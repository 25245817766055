// import idCard from "@/utils/idcard";

const NIS = ["NI", "GRP", "UU"]; // 身份证、港澳台居民居住证、老版身份证

const chinaCodes = ["CN", "MO", "TW", "HK"]; // 大陆、港澳台

const SPECIAL_NIS_CN_REQUIRES = ['MI'] //中文姓名必填的特殊证件类型

const CERT_NO_SPECIAL_FIELDS = ['certIssuePlace', 'nationality', 'certExpired'] //外国人证件，18位时不展示的特殊字段

// const certValidateMap = {
//     NI: val => {
//         const res = idCard.verify(val);
//         console.log("asdfa-", res);
//         return res;
//     }, // 身份证
//     GRP: val => val.length == 18, // 港澳台居民居住证
//     PP: val => val.length >= 5 && val.length <= 15, // 护照
//     IP: val => val.length >= 5 && val.length <= 15, // 电子护照
//     PF: val => val.length == 15, // 外国人永久居留证
//     MI: () => {} // 军方证件
// };

/**
 *
 * @param {*} condition 是否用配置来决定是否必填的 条件
 * @param {*} config 配置
 * @param {*} prop 哪一个字段属性
 * @param {*} nationality 国籍
 * @returns
 */
const _isRequireByCertTypeFromConfig = (form, config, prop) => {
  if (form?.certType == 'PF' && form?.certNo?.length == 18 && CERT_NO_SPECIAL_FIELDS.includes(prop)) {
    return false
  }
  if (!NIS.includes(form.certType) && config) {
    const configObj = JSON.parse(config);
    const propConfig = configObj[prop]?.toLocaleUpperCase() || "";
    if (propConfig == "TRUE") return true;
    else if (propConfig == "FALSE") return false;
    else if (propConfig && propConfig.indexOf(form.nationality)) return false;
    else return true;
  } else {
    return !NIS.includes(form.certType);
  }
};

const getFormValidateConfig = config => {
  return {
    name: {
      require:  form => NIS.includes(form.certType),
      label: "乘客姓名",
      rules: [
        {
          regFn: form => {
            const onlyChineseReg = /^[\u4e00-\u9fa5]+$/g;
            if (onlyChineseReg.test(form.name)) {
              if (form.name.length > 20) {
                return true;
              }
            }
          },
          desc: "纯汉字姓名长度不能超过20",
        },
        {
          regFn: form => {
            const onlyChineseReg = /^[\u4e00-\u9fa5]+$/g;
            if (!onlyChineseReg.test(form.name)) {
              if (form.name.length > 30) {
                return true;
              }
            }
          },
          desc: "汉字字母姓名长度不能超过30",
        },
      ],
    },
    firstName: {
      // form => !NIS.includes(form.certType) && chinaCodes.includes(form.nationality)
      require: form => !NIS.includes(form.certType),
      label: "英文姓",
      rules: [
        {
          regFn: form => {
            // eslint-disable-next-line no-useless-escape
            const reg = /[~\a-\z\A-\Z]/g;
            return !reg.test(form.firstName);
          },
          desc: "英文姓仅支持字母",
        },
        {
          regFn: form => form.firstName.length > 30,
          desc: "英文姓长度不能超过30",
        },
      ],
    },
    lastName: {
      require: form => {
        return !NIS.includes(form.certType);
      },
      label: "英文名",
    },

    firstNameZh: {
      require: form =>  SPECIAL_NIS_CN_REQUIRES.includes(form.certType) ,
      label: "中文姓",
    },
    lastNameZh: {
      require: form =>  SPECIAL_NIS_CN_REQUIRES.includes(form.certType),
      label: "中文名",
    },
    birthday: {
      require: form => !NIS.includes(form.certType),
      label: "出生日期",
    },
    mobile: {
      require: true,
      label: "电话",
      rules: [
        {
          regFn: form => {
            const reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
            return !reg.test(form.mobile.trim());
          },
          desc: "电话号码格式不正确",
        },
      ],
    },
    certNo: {
      require: true,
      label: "证件号",
      rules: [
        {
          regFn: (form, regularStr) => {
            if (regularStr) {
              const reg = new RegExp(regularStr);
              return !reg.test(form.certNo);
            }
            return false;
          },
          desc: "证件号格式不正确",
        },
      ],
    },
    // paxType: {
    //     require: true,
    //     label: "乘机人类型"
    // },
    certIssuePlace: {
      require: form => _isRequireByCertTypeFromConfig(form, config, "certIssuePlace"),
      label: "证书签发地",
    },
    certExpired: {
      require: form => _isRequireByCertTypeFromConfig(form, config, "certExpired"),
      label: "证件有效期",
    },
    certType: {
      require: true,
      label: "证件类型",
    },
    nationality: {
      require: form => _isRequireByCertTypeFromConfig(form, config, "nationality"),
      label: "国籍",
    },
  };
};

export { getFormValidateConfig, NIS, chinaCodes };
